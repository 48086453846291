import { Field, Form } from 'dataformjs';
import { httpsCallable } from 'firebase/functions';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { functions } from '../../../firebase';
import { spacesImportJson as importMutation } from '../../../objects/spaces/mutations';
import SpaceType from '../../../types/Space';
import Button from '../../base/Button';
import DataInputFileRender from '../../form/InputFile/Render';
import IconFileImport from '../../icons/FileImport';

interface HeaderImportFileProps {
  refetch?: () => void;
  space: SpaceType;
}

const HeaderImportFile: FC<HeaderImportFileProps> = ({ refetch, space }) => {
  const { t } = useTranslation();

  const [importMutationFunc] = useMutation(importMutation);

  const handleImportFileOnClick = () => {
    // if (importFileRef && importFileRef.current) {
    //   importFileRef.current.click();
    // }
    const element = document?.getElementById('inputFile');
    if (element) {
      element.click();
    }
  };

  const handleOnChangeFile = async (values: any) => {
    if (values) {
      console.info('handleOnChangeFile', values);
      handleOnSubmitFile(values);
    }
  };

  const handleOnSubmitFile = async (values: any) => {
    console.log('values', values.inputFile);
    const { inputFile } = values;
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(inputFile);
    fileReader.onload = async () => {
      const promise = new Promise((resolve, reject) => {
        const func = httpsCallable(functions, 'importXlsx');
        func({ file: fileReader.result })
          .then(result =>
            importMutationFunc({
              variables: {
                data: result.data,
                id: space.id,
              },
            }),
          )
          .then(resolve)
          .catch(reject);
      });

      await toast.promise(promise, {
        error: t('spaces.actions.import.submit.error'),
        pending: t('spaces.actions.import.submit.pending'),
        success: t('spaces.actions.import.submit.success'),
      });

      if (refetch) {
        refetch();
      }
    };
  };

  return (
    <>
      <Button
        iconLeft={IconFileImport}
        onClick={handleImportFileOnClick}
        size="small"
        status="neutral"
        title={t('space.actions.importFile')}
      />
      <Form
        className="hidden"
        name="uploadFile"
        onChange={handleOnChangeFile}
        onSubmit={handleOnSubmitFile}
      >
        <Field
          // ref={importFileRef}
          component={DataInputFileRender}
          formName="uploadFile"
          id="inputFile"
          name="inputFile"
          required
        />
      </Form>
    </>
  );
};

export default HeaderImportFile;
