import {
  change,
  DataFieldProps,
  useDispatch,
  WrappedFieldProps,
} from 'dataformjs';
import React, { FC } from 'react';

interface DataInputFileProps extends DataFieldProps {
  formName: string;
}

const DataInputFileRender: FC<WrappedFieldProps & DataInputFileProps> = ({
  formName,
  id = 'importFile',
  input: { name },
  type,
}) => {
  const dispatch = useDispatch();

  const handleOnChange = (event: any) => {
    if (event.target.files) {
      const file = event.target.files[0];
      dispatch(change(formName, name, file));
    }
  };

  return <input id={id} name={name} onChange={handleOnChange} type="file" />;
};
export default DataInputFileRender;
