import objectHash from 'object-hash';
import React, { FC, ReactElement, SyntheticEvent } from 'react';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';

import { activitiesUpdate as updateMutation } from '../../../objects/activities/mutations';
import PlaceType from '../../../types/Place';
import SpaceType from '../../../types/Space';
import UserType from '../../../types/User';
import IconEye from '../../icons/Eye';
import IconEyeSlash from '../../icons/EyeSlash';
import Item from '../../new/Item';
import ActivitiesItem from './Item';

interface SpaceActivitiesProps {
  refetch: () => void;
  space: SpaceType;
}

const SpaceActivities: FC<SpaceActivitiesProps> = ({ refetch, space }) => {
  const [updateMutationFunc] = useMutation(updateMutation);

  console.info('space', space);
  const { activities, places, users } = space;

  const handleToggleActiveOnClick = async (
    event: SyntheticEvent<HTMLDivElement>,
  ) => {
    const index = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    console.info('handleToggleActiveOnClick', index);
    if (
      undefined !== index &&
      !Number.isNaN(index) &&
      activities &&
      activities[index]
    ) {
      const result = await toast.promise(
        updateMutationFunc({
          variables: {
            data: {
              isActive: !activities[index].isActive,
            },
            id: activities[index].id,
          },
        }),
        {
          error: "Problème lors de la mise à jour de l'activité",
          pending: "En cours de mise à jour de l'activité",
          success: "Mise à jour de l'activité réussie",
        },
      );

      if (result.errors) {
        toast.error(result.errors.map(e => e.message).join(','));
      } else {
        refetch();
      }
    }
  };

  return (
    <div className="flex flex-col space-y-6">
      {!activities || (0 === activities.length && <div>Aucune activité</div>)}
      {activities?.map((activity, index) => {
        const place =
          places && places.find((p: PlaceType) => p.id === activity.placeId);
        const placeName = place && place.name;
        const address = place && place.formattedAddress;
        let titleStr: string | undefined;
        let subTitle: ReactElement | string | undefined;
        if (activity.userId) {
          const user =
            users && users.find((u: UserType) => u.id === activity.userId);
          titleStr = user && user.name;
          subTitle = (
            <div className="flex space-x-2">
              <span>{placeName}</span>
              <span className="italic text-light-900">{address}</span>
            </div>
          );
        } else {
          titleStr = placeName;
          subTitle = address;
        }

        const title = (
          <div
            className="flex space-x-2 items-center"
            data-index={index}
            onClick={handleToggleActiveOnClick}
            role="presentation"
          >
            {activity.isActive ? <IconEye /> : <IconEyeSlash />}
            <div>{titleStr}</div>
            {undefined !== activity.cost && activity.cost > 0 && (
              <div className="font-light text-xs">
                {Math.round(activity.cost / 100)} €
              </div>
            )}
            {undefined !== activity.distance && activity.distance > 0 && (
              <div className="font-light text-xs">
                {Math.round(activity.distance / 1000)} km
              </div>
            )}
            {undefined !== activity.duration && activity.duration > 0 && (
              <div className="font-light text-xs">
                {activity.duration / 60} min
              </div>
            )}
            {undefined !== activity.volume && activity.volume > 0 && (
              <div className="font-light text-xs">{activity.volume} m3</div>
            )}
            {undefined !== activity.weight && activity.weight > 0 && (
              <div className="font-light text-xs">{activity.weight} kg</div>
            )}
          </div>
        );

        return (
          <Item
            key={objectHash({ activityId: activity.id })}
            editPathname="/admin/activities/update/"
            id={activity.id}
            subTitle={subTitle}
            title={title}
          >
            {places && <ActivitiesItem item={activity} places={places} />}
          </Item>
        );
      })}
    </div>
  );
};

export default SpaceActivities;
