import dayjs from 'dayjs';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SpaceType from '../../../types/Space';
import List from '../../new/Body/List';
import Item from '../../new/Item';

interface SpacePlanningsProps {
  space: SpaceType;
}

const SpacePlannings: FC<SpacePlanningsProps> = ({ space }) => {
  const { t } = useTranslation();

  const { plannings } = space;

  return (
    <div className="flex flex-col space-y-6">
      {!plannings ||
        (0 === plannings.length && <div>{t('space.plannings.nothing')}</div>)}
      {plannings && plannings.length > 0 && (
        <List>
          {plannings?.map(planning => (
            <Item
              key={objectHash({ planningId: planning.id })}
              id={planning.id}
              showPathname="/admin/plannings/read/"
              subTitle={`Créé le ${dayjs(planning.createdAt).format(
                'dddd D MMMM YYYY à HH:mm',
              )}`}
              title={t('space.planning.nb-route', {
                count: planning.routes?.length,
              })}
            />
          ))}
        </List>
      )}
    </div>
  );
};

export default SpacePlannings;
