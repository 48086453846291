import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ActivityType from '../../../types/Activity';
import PlaceType from '../../../types/Place';
import ItemSub from '../../new/Item/Sub';

interface ActivitiesItemProps {
  item: ActivityType;
  places: PlaceType[];
}

const ActivitiesItem: FC<ActivitiesItemProps> = ({ item, places }) => {
  const { t } = useTranslation();

  const placesLUT = places ? keyBy(places, 'id') : undefined;

  if (!placesLUT) {
    return <div>Problème de paramètrage : places obligatoire</div>;
  }

  if (!item.deliveries || 0 === item.deliveries.length) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2">
      {compact(
        item.deliveries?.map(delivery => {
          if (!delivery.placeId) {
            return null;
          }

          const title = (
            <div className="flex space-x-2">
              <span>{placesLUT[delivery.placeId].name}</span>
              <span className="text-light-900 font-light italic">
                {placesLUT[delivery.placeId].formattedAddress}
              </span>
            </div>
          );

          const subTitle =
            delivery.containers?.length && delivery.containers.length > 0
              ? t('spaces.activities.deliveries.nb-containers', {
                  count: delivery.containers?.length,
                })
              : '';

          return (
            <ItemSub key={delivery.id} subTitle={subTitle} title={title} />
          );
        }),
      )}
    </div>
  );
};

export default ActivitiesItem;
