import { navigate } from 'gatsby';
import React, { FC, SyntheticEvent } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { activitiesCreate as createActivityMutation } from '../../../objects/activities/mutations';
import SpaceType from '../../../types/Space';
import Button from '../../base/Button';
import IconAdd from '../../icons/Add';
import Item from '../../new/Item';

interface SpacePlacesProps {
  space: SpaceType;
}

const SpacePlaces: FC<SpacePlacesProps> = ({ space }) => {
  const { t } = useTranslation();

  const [createActivityMutationFunc] = useMutation(createActivityMutation);

  const { id, places } = space;

  const handleCreateActivityOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const placeId = event.currentTarget.getAttribute('data-id');
    const result = await createActivityMutationFunc({
      variables: {
        placeId,
        spaceId: id,
      },
    });

    if (!result || result.errors) {
      console.error(result.errors);
    } else {
      const activityId = result.data.activitiesCreate.id;
      console.log('activityId', activityId);
      window.history.replaceState(
        {
          activityId,
          id,
        },
        '',
        `/admin/spaces/update/${id}/?tab=1`,
      );
      navigate(`/admin/activities/update/${activityId}/`);
    }
  };

  return (
    <div className="flex flex-col space-y-6">
      {!places || (0 === places.length && <div>Aucune adresse</div>)}

      {places?.map(place => (
        <Item
          key={place.id}
          customInfos={
            <Button
              className="text-light-900"
              datas={{
                'data-id': place.id,
              }}
              iconRight={IconAdd}
              onClick={handleCreateActivityOnClick}
              size="small"
              status="tertiary"
            >
              {t('activity.label')}
            </Button>
          }
          editPathname="/admin/places/update/"
          id={place.id}
          subTitle={place.formattedAddress}
          title={place.name}
        />
      ))}
    </div>
  );
};
export default SpacePlaces;
