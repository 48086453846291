import objectHash from 'object-hash';
import React, { FC } from 'react';

import SpaceType from '../../../types/Space';
import VehicleItem from '../../item/Vehicle';

interface SpaceVehiclesProps {
  space: SpaceType;
}

const SpaceVehicles: FC<SpaceVehiclesProps> = ({ space }) => {
  const { places, vehicles } = space;

  return (
    <div className="flex flex-col space-y-6">
      {!vehicles || (0 === vehicles.length && <div>Aucun véhicule</div>)}
      {vehicles?.map(vehicle => (
        <VehicleItem
          key={objectHash({ vehicleId: vehicle.id })}
          editPathname="/admin/vehicles/update/"
          places={places}
          vehicle={vehicle}
        />
      ))}
    </div>
  );
};

export default SpaceVehicles;
