import React, { FC } from 'react';

import IconProps from '../props';

/* eslint-disable max-len */

const IconPin: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <path
      d="M12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 7.325 5.318 9.715 8 12.944C10.682 9.714 12 7.325 12 6V6ZM8 16C4 11.542 2 8.21 2 6C2 2.686 4.686 0 8 0C11.314 0 14 2.686 14 6C14 8.21 12 11.542 8 16ZM8 8C6.895 8 6 7.105 6 6C6 4.895 6.895 4 8 4C9.105 4 10 4.895 10 6C10 7.105 9.105 8 8 8Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconPin;
