import { DocumentSnapshot, Unsubscribe } from '@firebase/firestore';
import { Form, submit, useDispatch } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../../../components/base/Button';
import GridSplit from '../../../../components/base/GridSplit';
import Tabs from '../../../../components/base/Tabs';
import IconCalculator from '../../../../components/icons/Calculator';
import IconCar from '../../../../components/icons/Car';
import IconFileExport from '../../../../components/icons/FileExport';
import IconPin from '../../../../components/icons/Pin';
import IconSave from '../../../../components/icons/Save';
import IconUser from '../../../../components/icons/User';
import SpaceActivities from '../../../../components/Space/Activities';
import AsideBody from '../../../../components/Space/Aside/Body';
import ImportFile from '../../../../components/Space/Header/ImportFile';
import SpacePlaces from '../../../../components/Space/Places';
import SpacePlannings from '../../../../components/Space/Plannings';
import SpaceUsers from '../../../../components/Space/Users';
import SpaceVehicles from '../../../../components/Space/Vehicles';
import Collection from '../../../../models/Collection';
import spaceData from '../../../../objects/spaces/datas.json';
import calculateMutation from '../../../../objects/spaces/mutations/calculate.graphql';
import updateMutation from '../../../../objects/spaces/mutations/update.graphql';
import spaceQuery from '../../../../objects/spaces/queries/space.graphql';
import SpaceType from '../../../../types/Space';
import requiredAuth from '../../../../utils/requireAuth';

const SpacePage: FC<PageProps> = ({ params: { id } }) => {
  const formName = 'space';
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [pending, setPending] = useState<boolean>(false);
  const [calculateMutationFunc] = useMutation(calculateMutation);
  const [updateMutationFunc] = useMutation(updateMutation);
  const { data, error, loading, refetch } = useQuery(spaceQuery, {
    variables: {
      id,
    },
  });

  const space: SpaceType = data && (data.space as SpaceType);

  useEffect(() => {
    let unsub: Unsubscribe;
    if (id && refetch) {
      if (typeof window !== `undefined`) {
        unsub = Collection.onSnapshot(
          'space',
          id,
          (snapshotDocument: DocumentSnapshot) => {
            const origin = snapshotDocument.get('origin');
            if ('estimation' === origin) {
              console.info('estimation');
            }

            return refetch();
          },
        );
      }
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [id, refetch]);

  const handleOnSubmit = async (values: any) => {
    const result = await toast.promise(
      updateMutationFunc({
        variables: {
          data: values,
          id: space.id,
        },
      }),
      {
        error: "Erreur lors de la mise à jour de l'espace",
        pending: "Mise à jour en cours de l'espace",

        success: "Mise à jour de l'espace réussie",
      },
    );

    if (result.errors) {
      toast.error(result.errors.map(e => e.message).join(','));
    } else {
      refetch();
    }
  };

  const handleSaveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(submit(formName));
  };

  const handleCalculOnClick = async () => {
    const promise = new Promise((resolve, reject) => {
      setPending(true);

      calculateMutationFunc({ variables: { id } })
        .then(() => {
          refetch();
          setPending(false);

          return resolve(true);
        })
        .catch(e => {
          setPending(false);

          reject(e);
        });
    });

    try {
      await toast.promise(promise, {
        error: t('space.actions.calculator.promise.error'),
        pending: t('space.actions.calculator.promise.pending'),
        success: t('space.actions.calculator.promise.success'),
      });
    } catch (e: unknown) {
      toast.error((e as Error).message);
    }
  };

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  if (!space) {
    return null;
  }

  return (
    <GridSplit
      aside={{
        body: space.places && <AsideBody places={space.places} />,
        className: 'sticky top-0 self-start',
        width: '40%',
      }}
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              iconLeft={IconSave}
              onClick={handleSaveOnClick}
              size="small"
              status="link"
              title={t('space.actions.update')}
            />
            <Button
              iconLeft={IconPin}
              size="small"
              status="neutral"
              title={t('space.actions.addPlace')}
              to={`/admin/places/create/?space=${space.id}`}
            />

            <Button
              iconLeft={IconUser}
              size="small"
              status="neutral"
              title={t('space.actions.addUser')}
              to={`/admin/users/create/?space=${space.id}`}
            />

            {space && space.places && space.places.length > 0 && (
              <Button
                iconLeft={IconCar}
                size="small"
                status="neutral"
                title={t('space.actions.addVehicle')}
                to={`/admin/vehicles/create/?space=${space.id}`}
              />
            )}

            <ImportFile refetch={refetch} space={space} />
            <Button
              iconLeft={IconFileExport}
              size="small"
              status="secondary"
              target="_blank"
              title={t('space.actions.export')}
              to={`/export/space/data-in/${id}`}
            />
            <Button
              disabled={pending}
              iconLeft={IconCalculator}
              onClick={handleCalculOnClick}
              size="small"
              title={t('space.actions.calculator.title')}
            />
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: t(`space.title`),
      }}
    >
      {loading && <div>{t(`space.loading`)}</div>}
      <Form
        className="mx-6 mb-6"
        datas={spaceData}
        enableReinitialize
        hideSubmitButton
        initialValues={space}
        name={formName}
        onSubmit={handleOnSubmit}
        params={{
          refetch,
        }}
      />
      <Tabs
        className="mx-6"
        values={[
          {
            component: <SpaceActivities refetch={refetch} space={space} />,
            name: 'Activités',
          },
          { component: <SpacePlaces space={space} />, name: 'Adresses' },
          { component: <SpaceUsers space={space} />, name: 'Usagers' },
          { component: <SpaceVehicles space={space} />, name: 'Véhicules' },
          { component: <SpacePlannings space={space} />, name: 'Plannings' },
        ]}
      />
    </GridSplit>
  );
};

export default requiredAuth(SpacePage, {
  admin: true,
  layout: true,
});
