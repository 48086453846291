import objectHash from 'object-hash';
import React, { FC } from 'react';

import SpaceType from '../../../types/Space';
import Item from '../../new/Item';

interface SpaceUsersProps {
  space: SpaceType;
}

const SpaceUsers: FC<SpaceUsersProps> = ({ space }) => {
  const { users } = space;

  return (
    <div className="flex flex-col space-y-6">
      {users?.map(user => (
        <Item
          key={objectHash({ userId: user.id })}
          editPathname="/admin/users/update/"
          id={user.id}
          subTitle={user.address}
          title={user.name}
        />
      ))}
    </div>
  );
};
export default SpaceUsers;
